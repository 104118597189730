import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Info = () => {
  return (
    <Collapse accordion defaultActiveKey={3} className='my-2' ghost>
      <Panel header='Общая информация' key={1}>
        <p>
          При успешном сбросе пароля на Вашу почту будет выслано письмо с логином и паролем для
          входа.
        </p>
      </Panel>
      <Panel header='Информация для диспетчеров и исполнителей' key={2}>
        <p>Введите адрес электронной почты (на который был зарегистрирован аккаунт)</p>
      </Panel>
      <Panel header='Информация для собственников жилых площадей' key={3}>
        <Collapse accordion ghost>
          <Panel header='Если есть хотя бы одна подтвержденная диспетчером собственность' key={1}>
            <p>
              Введите адрес электронной почты (на который был зарегистрирован аккаунт) и номер
              телефона (указанный в аккаунте).
            </p>
          </Panel>
          <Panel header='Если нет подтвержденных собственностей' key={2}>
            <p>Введите адрес электронной почты (на который был зарегистрирован аккаунт).</p>
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  );
};
